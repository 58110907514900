@use '@angular/material' as mat;
@include mat.core();

$blue-palette: (
  50: #EEF1F1,
  100: #D4DCDD,
  200: #B7C4C6,
  300: #9AACAF,
  400: #849B9E,
  500: #6E898D,
  600: #6E898D,
  700: #5B767A,
  800: #516C70,
  900: #3F595D,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: rgba(black, 0.87),
  )
);

$theme-primary: mat.define-palette($blue-palette);
$theme-accent: mat.define-palette($blue-palette);
$theme-warn: mat.define-palette(mat.$red-palette);
$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  )
));

@include mat.all-component-themes($theme);

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap');body {
  color: #6E898D;
  font-family: 'Open Sans', sans-serif;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a{
  text-decoration: none;
  color: inherit;
}

h2 {
  font-size: 24px;
}
u {
  padding-bottom:3px;
  text-decoration:none;
  border-bottom:2px solid #6E898D;
  &.text-white {
    border-bottom:2px solid #fff;
  }
}

.text-green {
  color: #6E898D;
}
.text-dark-green {
  color: #30575C;
}
.bg-green {
  background-color: #6E898D;
}
.bg-dark-green {
  background-color: #30575C;
}
.border-dark-green {
  border-color: #30575C !important;
}

.form-check-input:checked {
  background-color: #30575C;
  border-color: #6E898D;
}

.btn-primary {
  background-color: #6E898D;
  border: none;
  white-space: nowrap;
  width: fit-content;
  color: #fff;
  text-align: center;
  font: normal normal 600 13px/18px Open Sans;
  text-transform: uppercase;
  padding: 9px 16px;
  &:hover, &:active, &:focus {
    background-color: #30575C;
  }
}

.btn-outline-primary{
  white-space: nowrap;
  color: #30575C;
  text-align: center;
  font: normal normal 600 13px/18px Open Sans;
  text-transform: uppercase;
  padding: 9px 16px;
  border: 1px solid #30575C;
  &:hover, &:active, &:focus {
    background-color: #30575C;
    border: 1px solid #30575C;
    outline: 0;
  }
}

.btn-secondary {
  background-color: #30575C;
  border: none;
  color: #fff;
  white-space: nowrap;
  &:hover, &:active, &:focus {
    background-color: #30575C;
  }
}

.page-link {
  &.active {
    border-radius: 50%;
    padding: 5px 13px;
    background-color: #30575C;
    &:hover {
      background-color: #30575C;
      color: #fff;
    }
  }
  border: none;
  color: #30575C;
  &:hover {
    color: #30575C;

  }
  &:hover {
    background-color: transparent;
  }
}

.w-5 {
  width: 5%;
}

.f-24 {
  font-size: 24px;
}

.pointer-cursor {
  cursor: pointer;
}

@media (min-width: 992px) {

  .d-lg-table-header-group {
    display: table-header-group !important;
  }

}

@media (max-width: 992px) {

  tbody {
    tr {
      border-bottom: 1px solid  #f3f3f3;
      paddin-top: 15px;
    }
    td {
      display: block;
      border: none;
    }
  }
}

.prev, .next{
  font-size: 1.5rem;
  color: #fff !important;
  cursor: pointer;
}


// ajout / delete / qantité custom panier
.skin-2 .num-in {
  background: #30575C;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  height: 40px;
  width: 110px;
  float: left;
}

.in-num{
  background: #30575C;
  color: #fff;
}

.skin-2 .num-in span {
  width: 35%;
  display: block;
  height: 40px;
  float: left;
  position: relative;
}

.skin-2 .num-in span:before, .skin-2 .num-in span:after {
  content: '';
  position: absolute;
  background-color: #fff;
  height: 2px;
  width: 10px;
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -5px;
}

.skin-2 .num-in span.plus:after {
  transform: rotate(90deg);
}

.skin-2 .num-in input {
  float: left;
  width: 30%;
  height: 40px;
  border: none;
  text-align: center;
}

.minus, .plus {
  cursor: pointer;
}

.ngx-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .small-screen{
    display: none !important;
  }

  li:not(:first-child):not(:last-child) {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 5px 13px;

    & a:hover {
      background-color: #6E898D !important;
      color: #fff !important;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 5px 13px;
    }
  }

  .current{
    background-color: #30575C !important;
  }
}

.snackbar-success{
  background-color: rgb(161, 190, 194);
  color: #3F595D;
  font-weight: 500;
}

.snackbar-fail{
  background-color: rgb(199, 94, 94);
  color: #772727;
  font-weight: 500;
}

.carousel-title{
  h2{
    font: normal normal normal 46px/62px Open Sans;
    color: #FFFFFF !important;
    text-shadow: 0 0 8px #000000;
    opacity: 1;
    font-weight: 300;
  }

  h3{
    font: normal normal normal 22px/30px Open Sans;
    color: #FFFFFF !important;
    text-shadow: 0 0 8px #000000;
    opacity: 1;
    font-weight: 300;
  }
}
